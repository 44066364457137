import { observer } from 'mobx-react';
import { useState } from 'react';
import UsersView from '../UsersView/UsersView';
const ManagementView = observer(() => {
  const [selectedTab, setSelectedTab] = useState('');

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <div style={{ width: '100%', marginLeft: 50, marginRight: 50, marginBottom: 50 }}>
      <div style={{ display: 'flex', height: '8%'}}>
        <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8, display: 'flex', marginLeft: 10, marginTop: 10, alignItems: 'center'}}>
          Management
        </p>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div style={{ height: 30, width: 80, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontSize: 16 }} className="button" onClick={() => handleTabClick('accounts')}>
            Users
          </div>
        </div>
      </div>
      <div style={{display:'flex', height: '92%', backgroundColor: 'white'}}>
          {selectedTab === 'accounts' && <UsersView/>}
        </div>
    </div>
  );
})
export default ManagementView;