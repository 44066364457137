import React from 'react';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

function LogoutButton() {
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            console.log("User signed out");
            navigate('/');
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <button style={{ display: 'block', height: '100%', width: '100%', paddingBottom: '0.75rem', paddingTop: '0.75rem', paddingLeft: '1.25rem', paddingRight: '1.25rem',  backgroundColor: 'transparent', borderColor:'#e5e7eb',borderWidth: 0.5 , borderStyle: 'solid', cursor: 'pointer' }} onClick={handleLogout}>
            Logout
        </button>
    );
}
export default LogoutButton;