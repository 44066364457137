import { observer } from 'mobx-react';
import TabMenu from '../TabMenu/TabMenu';
import React, { useContext, useState } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import FormMenu from '../FormMenu/FormMenu.js';

let cards = [];

const LeftSection = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [isSaved, setIsSaved] = useState(true);

  let training = [
    { role: "system", content: `The following will generate blog posts based on keywords given as well as hashtags that match the blog post and make the blog post contain the best SEO friendly keywords as well as in a tone for example tone: funny or tone: sad etc and also return an accurate wordCount which is the number of words in the blog and put it in json objects and wrap the SEO friendly words or words good for SEO in <span style=\\"background: rgba(255,255,111,0.7);\\"> tags and <b> tags. IMPORTANT: the text property in the textResponse should have the exact number of words that have the wordCount property in the textResponse and not including the <span></span> nor <b></b> and also the text should not have the hashtags` },
    { role: 'user', content: 'dog, hound, mut tone:funny' },
    {
      role: 'assistant', content:
        `{
        "imageResponse": [
          {"image": "High-resolution photo-realistic image of a well-lit, open field. In the foreground, capture a dog in mid-motion, chasing after a brightly colored ball that is in the air. The dog's muscles should be tensed, showcasing its eagerness and speed. The background should be slightly blurred to emphasize the action, with tall, green grass swaying gently with the wind. The sky overhead should be clear with a hint of warm sunlight casting natural shadows on the ground, highlighting the main action of the dog and its toy. Important: The image must not contain any letters, words, text, or written symbols."},
          {"image": "High-resolution photo-realistic image of a serene lakeside scene. In the center, capture a dog swimming with determined strokes, water droplets cascading off its fur. The dog's eyes should be focused ahead, and its snout slightly raised above the shimmering water surface. Ripples should radiate around the dog, reflecting the sunlight. The surrounding water should gleam with the gentle touch of sunlight, with subtle reflections of surrounding trees. In the background, the calm lake extends to a tree-lined horizon, and the sky above is painted with soft, pastel hues of a setting sun. The overall mood should convey the peaceful and joyful experience of a dog enjoying its swim. Important: The image must not contain any letters, words, text, or written symbols."},
          {"image": "High-resolution photo-realistic image of a scenic park during golden hour. In the foreground, capture a dog, its fur illuminated by the soft sunlight, gazing up with adoring eyes at its owner. The owner, slightly bending down, should have a hand extended to gently caress the dog's head, their face radiating warmth and affection. The bond between them should be palpable. Their shadow, elongated by the setting sun, stretches out behind them on the grassy ground. In the background, tall trees with leaves rustling in the gentle breeze, a few park-goers at a distance, and a sun-kissed sky with hues of oranges and purples. The entire scene should convey a sense of love, trust, and the special bond between a dog and its owner. Important: The image must not contain any letters, words, text, or written symbols."}
        ],
        "textResponse": [
          {
              "title": "Man's Best Friend: Dogs",
              "wordCount": "70",
              "hashtags": ["#dogs", "#mansbestfriend", "#loyal", "#loving", "#doglover"],
              "text": "Dogs are truly <span style=\\"background: rgba(255,255,111,0.7);\\"><b>man's best friend</b></span>. They bring <span style=\\"background: rgba(255,255,111,0.7);\\"><b>joy</b></span>, <span style=\\"background: rgba(255,255,111,0.7);\\"><b>loyalty</b></span>, and <span style=\\"background: rgba(255,255,111,0.7);\\"><b>unconditional love</b></span> to our lives. From wagging tails to slobbery kisses, these furry friends are always there to brighten our day. Whether they're chasing their tails or cuddling on the couch, dogs never fail to make us laugh. If you're a true <span style=\\"background: rgba(255,255,111,0.7);\\"><b>dog lover</b></span>, you know that life is simply better with a loyal canine companion by your side."
          },
          {
              "title": "The Unconditional Love of Dogs",
              "wordCount": "48",
              "hashtags": ["#pups", "#doglovers", "#dogowners", "#muts", "#pets"],
              "text": "There's nothing quite like the <span style=\\"background: rgba(255,255,111,0.7);\\"><b>unconditional love</b></span> of <span style=\\"background: rgba(255,255,111,0.7);\\"><b>dogs</b></span>. These <span style=\\"background: rgba(255,255,111,0.7);\\"><b>loyal</b></span> <span style=\\"background: rgba(255,255,111,0.7);\\"><b>companions</b></span> bring endless joy to <span style=\\"background: rgba(255,255,111,0.7);\\"><b>dog owners</b></span> everywhere. Whether you have a purebred or a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>mutt</b></span>, their love is always genuine. <span style=\\"background: rgba(255,255,111,0.7);\\"><b>Pups</b></span> don't care about your bad hair days or your old pajamas-they love you for you."
          },
          {
              "title": "The Joy of Owning a Dog",
              "wordCount": "90",
              "hashtags": ["#familydog", "#dogworld", "#doggies", "#puppy", "#paws"],
              "text": "Owning a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>dog</b></span> is a joy like no other! These furry companions bring endless laughter and happiness into our lives. From their playful antics to their unwavering loyalty, dogs truly are a part of the <span style=\\"background: rgba(255,255,111,0.7);\\"><b>family</b></span>. Whether it’s a rambunctious <span style=\\"background: rgba(255,255,111,0.7);\\"><b>puppy</b></span> or a calm older dog, the bond you form is unbreakable. They have a way of making every day brighter and more fulfilling. So, if you’re thinking about adding a furry friend to your life, remember, a dog’s love is unconditional and their impact on your life is immeasurable."
          }
        ]
      }`
    },
    { role: 'user', content: 'Background Check, Verification, History tone:informative' },
    {
      role: 'assistant', content:
        `{
        "imageResponse": [
          {"image": "A magnifying glass floating above a dynamic mosaic floor, each tile representing a unique event or chapter in a person's life, illuminating specific tiles as it hovers, revealing a bigger picture. Important: The image must not contain any letters, words, text, or written symbols."},
          {"image": "A vast tapestry spread out under a spotlight, its myriad of woven patterns representing different facets of a person's life. A meticulous hand hovers, identifying and tracing certain standout threads, revealing their significance. Important: The image must not contain any letters, words, text, or written symbols."},
          {"image": "A sophisticated scanner beam moving systematically across a vast grid, each cell holding a fragment of someone's history. As the beam passes, certain cells light up and magnify, revealing their depth and importance. Important: The image must not contain any letters, words, text, or written symbols."}
        ],
        "textResponse": [
          {
              "title": "The Importance of Background Checks",
              "wordCount": "80",
              "hashtags": ["#BackgroundChecks", "#security", "#screening", "#history", "#trust"],
              "text": "When hiring, <span style=\\"background: rgba(255,255,111,0.7);\\"><b>background checks</b></span> are crucial for ensuring <span style=\\"background: rgba(255,255,111,0.7);\\"><b>security</b></span>. They help screen candidates by revealing their <span style=\\"background: rgba(255,255,111,0.7);\\"><b>history</b></span>, protecting your business from potential risks. Think of it like a trust fall: you wouldn't let just anyone catch you, right? A thorough background check provides peace of mind and <span style=\\"background: rgba(255,255,111,0.7);\\"><b>trust</b></span> in your team, making it a key component of a safe hiring process. After all, no one wants to find out too late that their new hire has a history of <span style=\\"background: rgba(255,255,111,0.7);\\"><b>trouble</b></span>."
          },
          {
              "title": "Screening for Security: The Role of Background Checks",
              "wordCount": "81",
              "hashtags": ["#SafetyFirst", "#BackgroundChecks", "#History", "#Security", "#Screening"],
              "text": "Ever wondered how some people get to join your team without leaving you questioning their past? Enter the <span style="\\"background: rgba(255,255,111,0.7);\\"><b>background check</b></span>—a superhero in disguise! These <security style=\\"background: rgba(255,255,111,0.7);\\"><b>security</b></span> sleuths dig deep, ensuring that the person you’re about to hire doesn’t come with a side of criminal records. Imagine hiring someone who’s a cross between a saint and a secret agent—now that's what we call a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>safe</b></span> bet! So, the next time you see those forms, remember: they’re not just paperwork; they’re your <span style=\\"background: rgba(255,255,111,0.7);\\"><b>history</b></span> detectives."
          },
          {
              "title": "Background Checks: A Window into History",
              "wordCount": "79",
              "hashtags": ["#BackgroundChecks", "#screening", "#history", "#security", "#safety"],
              "text": "Ever wondered what’s behind a background check? It’s not just a boring procedure; it’s like peeking through a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>time capsule</b></span> into someone’s past. These <span style=\\"background: rgba(255,255,111,0.7);\\"><b>security</b></span> checks reveal a person’s history, ensuring you’re not hiring a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>pirate</b></span> or a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>time traveler</b></span>. So, whether you're bringing a new <span style=\\"background: rgba(255,255,111,0.7);\\"><b>employee</b></span> on board or just curious about the <span style=\\"background: rgba(255,255,111,0.7);\\"><b>history</b></span> behind your next date, background checks offer a <span style=\\"background: rgba(255,255,111,0.7);\\"><b>safety</b></span> net to keep you informed. Remember, a quick check is worth its weight in <span style=\\"background: rgba(255,255,111,0.7);\\"><b>gold</b></span>!"
          }
        ]
      }`
    },
    { role: 'user', content: `${accountStore.keyword1}, ${accountStore.keyword2}, ${accountStore.keyword3}, ${accountStore.hashtags}, wordCount: ${accountStore.wordCount}, wordCount: ${accountStore.wordCount}, wordCount: ${accountStore.wordCount}, tone: ${accountStore.tone !== '' ? accountStore.tone : 'blogger'}` },
  ];

  async function getData(training) {
    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accountStore.openAIKey}`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: training
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const chatText = await response.text();
      const chatObject = JSON.parse(chatText);

      training.push({
        role: "assistant",
        content: JSON.stringify(chatText),
      });

      return chatObject;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const generateImage = async (description) => {
    const response = await fetch("https://api.openai.com/v1/images/generations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accountStore.openAIKey}`
      },
      body: JSON.stringify({
        prompt: description,
        size: "1024x1024"
      }),
    });

    const data = await response.json();
    return data;
  };

  async function generateImagesFromDescriptions(descriptions) {
    let imageArr = [];

    for (let i = 0; i < descriptions.length; i++) {
      try {
        let data = await generateImage(descriptions[i]);
        console.log(data);
        imageArr.push(data.data[0].url);
      } catch (err) {
        console.error(err);
      }
    }

    return imageArr;
  }

  return (
    <div id="left-section" style={{ width: '100%', maxWidth: 288, height: '100vh', position: 'fixed', boxShadow: '0px 0px 32px rgba(0,0,0,0.1)', marginRight: 20, top: 56, left: 0, background: '#FFFFFF', overflowY: 'auto' }}>
      <p style={{ fontSize: 33, fontWeight: 'bold', fontFamily: 'nexa_boldregular', margin: 8 }}>Prep</p>
      <div style={{ background: 'white', marginBottom: 16 }}>
        <TabMenu />
      </div>
      <FormMenu />
      <div style={{ display: 'flex', justifyContent: "center", marginTop: 16, marginBottom: 16 }}>
        <div style={{ height: 30, width: 70, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, marginLeft: 10, marginTop: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontSize: 16 }}
          className="button"
          onClick={async () => {
            if (!accountStore || !accountStore.accountName) {
              console.error("accountStore or accountStore.accountName is undefined");
              return;
            }

            setIsSaved(false);
            const auth = getAuth();
            const user = auth.currentUser;
            const db = getFirestore();
            const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
            const querySnapshot = await getDocs(q);
            let accounts = [];

            querySnapshot.forEach((doc) => {
              const account = doc.data();
              accounts.push(account);
            });

            if (user) {
              const q = query(collection(db, "accounts"), where("accountName", "==", accountStore.accountName));
              const querySnapshot = await getDocs(q);

              if (!querySnapshot.empty) {
                const docData = querySnapshot.docs[0];
                const accountDoc = doc(db, "accounts", docData.id);
                console.log("Updating document:", accountDoc);
                console.log("Start Date:", accountStore.startDate);
                console.log("accountStore.keyword1", accountStore.keyword1);

                try {
                  await updateDoc(accountDoc, {
                    keyword1: accountStore.keyword1,
                    keyword2: accountStore.keyword2,
                    keyword3: accountStore.keyword3,
                    daily: accountStore.daily,
                    startDate: accountStore.startDate,
                    backlink: accountStore.backlink,
                    hashtags: accountStore.hashtags,
                    tone: accountStore.tone,
                    wordCount: accountStore.wordCount,
                  });
                } catch (error) {
                  console.error("Error updating document:", error);
                }
              } else {
                console.error("No document found with accountName:", user.accountName);
              }
            }
            setIsSaved(true);
          }}>
          {accountStore.selectedAccount !== "" && isSaved === false ? 'Saving...' : 'Save'}
        </div>
        <div style={{ height: 30, width: 70, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, borderRadius: 10, marginLeft: 10, marginTop: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular' }}
          className="button"
          onClick={async () => {
            if (accountStore.running === false) {
              accountStore.setRunning(true);
              try {
                accountStore.setGeneratingContent(true);
                accountStore.setHamburgerOpen(false);
                let result = await getData(training);
                cards = [];

                for (let i = 0; i < result.choices.length; i++) {
                  let message = JSON.parse(result.choices[i].message.content);
                  for (let j = 0; j < message.textResponse.length; j++) {
                    let obj = {};
                    let textResponse = message.textResponse[j];
                    obj.text = textResponse.text;
                    obj.title = textResponse.title;
                    obj.hashtags = accountStore.signatures !== undefined || accountStore.signatures !== null ? [...accountStore.signatures, ...textResponse.hashtags] : textResponse.hashtags;
                    obj.wordCount = textResponse.wordCount;
                    obj.imageDescription = message.imageResponse[i].image;
                    cards.push(obj);
                  }
                }

                try {
                  let images = await generateImagesFromDescriptions([cards[0].imageDescription, cards[1].imageDescription, cards[2].imageDescription]);
                  console.log(images);

                  for (let m = 0; m < images.length; m++) {
                    cards[m].image = images[m];
                  }

                  console.log('cards', cards)
                  accountStore.setCards([]);
                  accountStore.setCards(cards);
                  accountStore.setInitialImage(cards[0].image);
                  accountStore.setInitialText(cards[0].text);
                  accountStore.setGeneratingContent(false);
                  accountStore.setRunning(false);
                } catch (err) {
                  console.error(err);
                }
              } catch (error) {
                console.error("Error updating document:", error);
              }
            } else {
              accountStore.setRunning(false);
            }
            console.log("Keyword Run");
          }}>
          {accountStore.running === true ? 'Pause' : 'Preview'}
        </div>
      </div>
    </div>
  );
})
export default LeftSection;