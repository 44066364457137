import React from 'react';

function ManagementButton({ onClick }) {

  return (
    <button style={{ display: 'block', height: '100%', width: '100%', paddingBottom: '0.75rem', paddingTop: '0.75rem', paddingLeft: '1.25rem', paddingRight: '1.25rem', backgroundColor: 'transparent', borderColor: '#e5e7eb', borderWidth: 0.5, borderStyle: 'solid', cursor: 'pointer' }} onClick={onClick}>
      Management
    </button>
  );
}
export default ManagementButton;