import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import { AccountStoreContext } from '../store/AccountStore.js';
import { observer } from 'mobx-react-lite';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import Header from '../Header/Header.js';
import LeftSection from '../LeftSection/LeftSection.js';
import PreviewSection from '../PreviewSection/PreviewSection.js';
import RightSection from '../RightSection/RightSection.js';
import ProfileSection from '../ProfileSection/ProfileSection.js';
import ManagementView from '../ManagementView/ManagementView.js';
import { getFunctions, httpsCallable } from "firebase/functions";
import { jwtDecode } from "jwt-decode";
import { getAuth } from "firebase/auth";

const Dashboard = observer(() => {
  const user = useContext(AuthContext);
  const accountStore = useContext(AccountStoreContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [managementSelected, setManagement] = useState(false);

  useEffect(() => {
    const fetchAllAccounts = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error("User is not authenticated.");
        return;
      }

      const functions = getFunctions();
      const getAllAccounts = httpsCallable(functions, 'getAllAccounts');

      try {
        const result = await getAllAccounts();
        accountStore.setAccounts(result.data.accounts);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    const fetchAccounts = async () => {
      const db = getFirestore();
      const q = query(collection(db, "accounts"), where("displayName", "==", user?.displayName));
      const querySnapshot = await getDocs(q);
      let accounts = [];
      querySnapshot.forEach((doc) => {
        accounts.push(doc.data());
      });
      accountStore.setAccounts(accounts);
    };

    const checkAdminAndFetch = async () => {
      if (user) {
        try {
          const idToken = await user.getIdToken();
          const decodedToken = jwtDecode(idToken);

          if (decodedToken.admin) {
            fetchAllAccounts();  // If admin, fetch all accounts
            setIsAdmin(true);
          } else {
            fetchAccounts();  // If not admin, fetch only their own accounts
          }
        } catch (error) {
          console.error("Error fetching user claims or token:", error);
        }
      }
    };

    checkAdminAndFetch();
  }, [accountStore, user]);

  const handleButtonClick = () => {
    console.log('button pretado');
    setManagement(!managementSelected);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `}
      </style>
      <div style={{ display: 'flex', width: '100%', height: 56, background: '#FFFFFF', alignItems: 'center' }}>
        <Header isAdmin={isAdmin} onButtonClick={handleButtonClick} />
      </div>
      {!accountStore.showProfile ? (
        <div>
          {managementSelected ?
            <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
              <ManagementView />
            </div> :
            <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
              <LeftSection />
              <PreviewSection />
              <RightSection />
            </div>}
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 56px)', overflowX: 'hidden' }}>
          <ProfileSection />
        </div>
      )}
    </div>
  );
})
export default Dashboard;