import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const TwitterPreviewMobile = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: 8, overflow: 'hidden', height: '100%', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
            <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 4 }} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span style={{ fontFamily: 'Arial, sans-serif', fontSize: 14, fontWeight: 'bold' }}>
                {accountStore.accountName}
              </span>
              <span style={{ marginLeft: 6, color: '#657786', fontSize: 10 }}>
                @{accountStore.accountName} • 1m
              </span>
            </div>
          </div>
          <div>
            <p style={{ margin: 0, fontSize: 14, color: '#14171A' }}>
              <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} />
            </p>
            <div style={{ color: '#1DA1F2', fontSize: 12 }}>
              {accountStore.cards[0].hashtags}
            </div>
            {accountStore.cards[0].image && (
              <img src={accountStore.cards[0].image} alt="Tweet" style={{ width: '100%', borderRadius: 12, marginTop: 8, border: '1px solid #E1E8ED' }} />
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #E1E8ED', fontSize: 12, color: '#657786', paddingBottom: 10, paddingTop: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./replyTwitter.svg" alt="Reply" style={{ height: 16, marginRight: 6 }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./repostTwitter.svg" alt="Retweet" style={{ height: 16, marginRight: 6 }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./heart.svg" alt="Like" style={{ height: 16, marginRight: 6 }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./stats.svg" alt="Stats" style={{ height: 16, marginRight: 6 }} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', width: '30%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./bookmark.svg" alt="Bookmark" style={{ height: 16, marginRight: 6 }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./shareTwitter.svg" alt="Share" style={{ height: 16, marginRight: 6 }} />
            </div>
          </div>
        </div>
      </div>
    );
});
export default TwitterPreviewMobile;