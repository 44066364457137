import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AccountStoreContext } from '../store/AccountStore';
import { getAuth } from 'firebase/auth';
import InventoryList from '../InventoryList/InventoryList';

const checkFieldsNotNull = (accountStore) => {
  if (accountStore.selectedAccount === null || accountStore.selectedAccount === undefined) {
    return "You must select an account first";
  }

  const fields = [
    { value: accountStore.keyword1, name: "Keyword 1" },
    { value: accountStore.keyword2, name: "Keyword 2" },
    { value: accountStore.keyword3, name: "Keyword 3" },
    { value: accountStore.daily, name: "Daily" },
    { value: accountStore.startDate, name: "Start Date" },
    { value: accountStore.backlink, name: "Backlink" },
    { value: accountStore.hashtags, name: "Hashtags" },
    { value: accountStore.tone, name: "Tone" },
    { value: accountStore.wordCount, name: "WordCount"},
  ];

  for (let field of fields) {
    if (!field.value) {
      return `Please fill in the ${field.name} field before saving.`;
    }
  }

  return null;
}

const TabMenu = observer(() => {
  const [activeTab, setActiveTab] = useState('Keywords');
  const accountStore = useContext(AccountStoreContext);
  const auth = getAuth();
  const user = auth.currentUser;
  const [, setIsSaved] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (user) {
      console.log("Logged in user:", user);
    }
  }, [user]);

  useEffect(() => {
    const error = checkFieldsNotNull(accountStore);
    if (error) {
      setIsSaved(false);
      setErrorMessage(error);
    } else {
      setIsSaved(true);
      setErrorMessage(null);
    }
  }, [accountStore.keyword1, accountStore.keyword2, accountStore.keyword3, accountStore.daily, accountStore.startDate, accountStore.backlink, accountStore.hashtags, accountStore.tone, accountStore.startTime, accountStore.selectedAccount, accountStore.wordCount, accountStore]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Keywords':
        return (
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', margin: 'auto', background: 'white', alignItems: 'center', paddingTop: 8 }}>
            <input id="keyword1" style={{ background: '#F5F5F5', borderRadius: 8, border: '0px solid', height: '30px', margin: '5px 7px', maxWidth: 270, paddingLeft: 10 }} 
              type="text"
              placeholder="Keyword 1"
              onChange={(e) => {
                accountStore.setKeyword1(e.target.value);
              }} 
              value={accountStore.keyword1} />
            <input id="keyword2" style={{ background: '#F5F5F5', borderRadius: 8, border: '0px solid', height: '30px', margin: '5px 7px', maxWidth: 270, paddingLeft: 10 }}
              type="text" 
              placeholder="Keyword 2"
              onChange={(e) => {
                accountStore.setKeyword2(e.target.value);
              }} 
              value={accountStore.keyword2} />
            <input id="keyword3" style={{ background: '#F5F5F5', borderRadius: 8, border: '0px solid', height: '30px', margin: '5px 7px', maxWidth: 270, paddingLeft: 10 }} 
              type="text" 
              placeholder="Keyword 3"
              onChange={(e) => {
                accountStore.setKeyword3(e.target.value);
              }}
              value={accountStore.keyword3} />
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
              {errorMessage && 
              <div style={{ fontSize: 10, marginTop: 10, color: 'red', }}>
                {errorMessage}
              </div>
              }
            </div>
          </div>
        );

      case 'Inventory':
        return (
          <div>
            <InventoryList />
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', height: 30, boxShadow: 'rgba(0, 0, 0, 0.1) 0px -1px 0px inset' }}>
        <span
          style={{ cursor: 'pointer', fontWeight: 'bold', marginLeft: 0, fontSize: 11, paddingBottom: 11, borderBottom: activeTab === 'Keywords' ? '2px solid blue' : '0px', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'nexa_boldregular' }}
          onClick={() => setActiveTab('Keywords')}>
          Keywords
        </span>
        <span
          style={{ cursor: 'pointer', fontWeight: 'bold', marginLeft: 0, fontSize: 11, paddingBottom: 11, borderBottom: activeTab === 'Inventory' ? '2px solid blue' : '0px', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'nexa_boldregular' }}
          onClick={() => setActiveTab('Inventory')}>
          Inventory
        </span>
      </div>
      {renderTabContent()}
    </div>
  );
})
export default TabMenu;