import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const BlogPreviewMobile = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#FFFFFF', borderRadius: 8, overflow: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
          <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 8 }} />
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: 14, fontWeight: 'bold' }}>
            {accountStore.accountName}
          </span>
        </div>
        <h2 style={{ fontSize: 18, marginBottom: 8, margin: 0 }}>
          {accountStore.cards[0].title}
        </h2>
        {accountStore.cards[0].image && (
          <img src={accountStore.cards[0].image} alt="Blog" style={{ width: '100%', height: 'auto', borderRadius: 12, marginBottom: 8 }} />
        )}
        <p style={{ margin: 0, fontSize: 14, color: '#333', lineHeight: '1.4', paddingLeft: 4, paddingRight: 4, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
          <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} />
        </p>
        <div style={{ color: '#0073b1', fontSize: 12, marginTop: 8 }}>
          {accountStore.cards[0].hashtags}
        </div>
      </div>
    );
});
export default BlogPreviewMobile;