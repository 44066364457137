import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import LogoutButton from '../LogoutButton/LogoutButton';
import ManagementButton from '../ManagementButton/ManagementButton.js';
import AccountDropdown from '../AccountDropdown/AccountDropdown';
import { AccountStoreContext } from '../store/AccountStore.js';

const Header = observer(({ isAdmin, onButtonClick }) => {
  const user = useContext(AuthContext);
  const accountStore = useContext(AccountStoreContext);
  const [showMenu, setShowMenu] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const photoURL = user ? user.photoURL : null;
  const [activeDropdown, setActiveDropdown] = useState(false);

  const toggleDropdown = () => {
    setActiveDropdown(!activeDropdown);
  };

  const closeMenu = () => {
    setShowMenu(false);
  }

  useEffect(() => {
    setProfileImage(photoURL);
  }, [photoURL, user]);

  return (
    <div style={{ width: '100%', height: 48, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ height: 40, width: 40, borderRadius: '50%', overflow: 'hidden', marginLeft: 20, cursor: 'pointer' }}
          onClick={() => {
            if (showMenu === false) {
              setShowMenu(true);
            } else {
              setShowMenu(false);
            }
          }}>
          <img src={profileImage} style={{ height: '100%', width: '100%' }} alt="" />
        </div>
        <div style={{ marginLeft: 10 }}>
          <p style={{ fontWeight: 'bold', margin: 0, fontFamily: 'nexa_boldregular' }}>
            Hello, {user ? user.displayName : "Loading..."}
          </p>
          <div id="menu" style={{ display: showMenu === true ? 'flex' : 'none', flexDirection: 'column', position: 'absolute', background: '#FFFFFF', zIndex: 10, left: 30, top: 59, justifyContent: 'center', alignItems: 'center' }}>
            <ul style={{ borderColor: '#e5e7eb', borderWidth: 0, borderStyle: 'solid', boxSizing: 'border-box', listStyle: 'none', padding: 0, margin: 0, backgroundColor: '#FFFFFF' }}>
              <li onClick={closeMenu}>
                {isAdmin && (<ManagementButton onClick={onButtonClick} />)}
              </li>
              <li onClick={closeMenu}>
                <LogoutButton />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{ width: 288 }}>
        <div style={{ display: 'flex', width: 'calc(100% - 40px)', position: 'relative', alignItems: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer', fontFamily: 'nexa_boldregular', fontWeight: 'bold', fontSize: 18 }}
          onClick={toggleDropdown}>
          <img src={accountStore.selectedAccountData && accountStore.selectedAccountData.profileImage ? accountStore.selectedAccountData.profileImage : './user.svg'} alt="" style={{ marginRight: 10, width: 30, height: 30, borderRadius: '50%', backgroundSize: 'contain' }} />
          {accountStore.selectedAccountData && accountStore.selectedAccountData.accountName ? accountStore.selectedAccountData.accountName : "Select Account"}
          <span style={{ position: 'absolute', right: 0, transform: activeDropdown === true ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
            <img src="./down-arrow.svg" alt="" />
          </span>
        </div>
        {activeDropdown && (
          <div style={{ width: 288, position: 'absolute', background: '#FFFFFF', zIndex: 10, top: 56, right: 0, boxShadow: 'rgba(0, 0, 0, 0.1) -4px 4px 8px' }}>
            <AccountDropdown />
          </div>
        )}
      </div>
    </div>
  );
})
export default Header;