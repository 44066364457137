import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';  // Import the provider
import { AccountStoreContext } from './store/AccountStore';  // Import the context
import LoginBox from './LoginBox/LoginBox';  // Import your LoginBox component
import Dashboard from './Dashboard/Dashboard';  // Import the component to show after login
import './App.css';

const App = () => {
  return (
    <AccountStoreContext.Provider value={AccountStoreContext._currentValue}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginBox />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
      </AuthProvider>
    </AccountStoreContext.Provider>
  );
}
export default App;