import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const FacebookPreview = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#FFFFFF', borderRadius: 8, boxShadow: '0 2px 5px rgba(0,0,0,0.2)', padding: 4 }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 12 }}>
                <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <span style={{ fontFamily: 'nexa_boldregular', fontSize: 16 }}>
                        {accountStore.accountName}
                    </span>
                    <span style={{ fontSize: 12, color: 'gray' }}>
                        1 minute ago
                    </span>
                </div>
            </div>
            <h2 style={{ fontSize: 18, marginBottom: 8, marginTop: 0, fontFamily: 'Arial, sans-serif' }}>
                {accountStore.cards[0].title}
            </h2>
            <img src={accountStore.cards[0].image} alt="" style={{ borderRadius: 10, height: 'auto', width: '100%', maxHeight: 100, objectFit: 'cover' }} />
            <p dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} style={{ marginTop: 12, marginBottom: 12, fontSize: 14, lineHeight: '1.5', color: '#333', fontFamily: 'Arial, sans-serif' }} />
            <div style={{ color: '#4267B2', fontSize: 14, marginBottom: 12 }}>
                {accountStore.cards[0].hashtags}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderTop: '1px solid #ddd', paddingTop: 8 }}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770' }}>
                    <img src="./like.svg" alt="Like" style={{ height: 20, marginRight: 8 }} /> 
                    Like
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770' }}>
                    <img src="./comment.svg" alt="Comment" style={{ height: 20, marginRight: 8 }} /> 
                    Comment
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770' }}>
                    <img src="./whatsapp.svg" alt="Comment" style={{ height: 20, marginRight: 8 }} /> 
                    Send
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770' }}>
                    <img src="./share.svg" alt="Share" style={{ height: 20, marginRight: 8 }} />
                    Share
                </div>
            </div>
        </div>
    );
});
export default FacebookPreview;