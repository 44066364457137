import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const FacebookPreviewMobile = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderRadius: 8, justifyContent: 'space-between', height: '100%', width: '100%', boxSizing: 'border-box' }}>
            <div style={{ width: '100%'}}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                    <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <span style={{ fontFamily: 'nexa_boldregular', fontSize: 14 }}>
                            {accountStore.accountName}
                        </span>
                        <span style={{ fontSize: 10, color: 'gray' }}>
                            1 minute ago
                        </span>
                    </div>
                </div>
                <div style={{ padding: '0px 4px'}}>
                    <div style={{ marginTop: 12, fontSize: 12, lineHeight: '1.4', color: '#333', fontFamily: 'Arial, sans-serif', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', maxWidth: '100%', wordWrap: 'break-word' }}>
                        {accountStore.cards[0].title}
                        <p dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} style={{ margin: 0, maxWidth: '100%' }}/>
                    </div>
                    <div style={{ maxWidth: '100%' ,color: '#4267B2', fontSize: 12, marginBottom: 12, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', wordWrap: 'break-word' }}>
                        {accountStore.cards[0].hashtags}
                    </div>
                    <img src={accountStore.cards[0].image} alt="" style={{ borderRadius: 10, height: 'auto', width: '100%', objectFit: 'cover', marginBottom: 8 }} />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderTop: '1px solid #ddd', paddingTop: 8, justifyContent:'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <img src="./like.svg" alt="Like" style={{ height: 20 }} /> 
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770', marginBottom: 8 }}>
                    <img src="./comment.svg" alt="Comment" style={{ height: 20 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770', marginBottom: 8 }}>
                    <img src="./whatsapp.svg" alt="Comment" style={{ height: 20 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#606770', marginBottom: 8 }}>
                    <img src="./share.svg" alt="Share" style={{ height: 20 }} />
                </div>
            </div>
        </div>
    );
});
export default FacebookPreviewMobile;