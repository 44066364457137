import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const LinkedInPreview = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
        <div style={{ backgroundColor: '#FFFFFF', borderRadius: 8, boxShadow: '0 2px 5px rgba(0,0,0,0.1)', overflow: 'hidden', padding: 2 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 3, borderBottom: '1px solid #ddd' }}>
                <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
                <div>
                    <span style={{ fontFamily: 'nexa_boldregular', fontSize: 14 }}>
                        {accountStore.accountName}
                    </span>
                    <div style={{ fontSize: 12, color: '#666' }}>
                        1 minute ago
                    </div>
                </div>
            </div>
            <div style={{ padding: '12px', fontFamily: 'Arial, sans-serif' }}>
                <p style={{ margin: 0, fontSize: 14, lineHeight: '2', color: '#333', maxHeight: 28, overflow: 'hidden' }}>
                    {accountStore.cards[0].title}
                    <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} />
                </p>
                <div style={{ color: '#0073b1', fontSize: 14, lineHeight: '2', maxHeight: 28 }}>
                    {accountStore.cards[0].hashtags}
                </div>
            </div>
            <img src={accountStore.cards[0].image} alt="Post" style={{ width: '100%', maxHeight: 219, objectFit: 'cover' }} />  
            
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px', borderTop: '1px solid #ddd' }}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
                    <img src="./likeLinkedIn.svg" alt="Like" style={{ height: 24, marginRight: 8 }} /> 
                    Like
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
                    <img src="./commentLinkedIn.svg" alt="Comment" style={{ height: 24, marginRight: 8 }} /> 
                    Comment
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
                    <img src="./shareLinkedIn.svg" alt="Share" style={{ height: 24, marginRight: 8 }} /> 
                    Share
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
                    <img src="./sendLinkedIn.svg" alt="Share" style={{ height: 24, marginRight: 8 }} /> 
                    Send
                </div>
            </div>
        </div>
    );
});
export default LinkedInPreview;