import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const InstagramPreview = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };
    return(
        <div style={{ backgroundColor: '#FFFFFF', borderRadius: 8, boxShadow: '0 2px 5px rgba(0,0,0,0.2)', overflow: 'hidden', padding: 2 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
                <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 12 }} />
                <span style={{ fontFamily: 'nexa_boldregular', fontSize: 14 }}>
                    {accountStore.accountName} • 1m
                </span>
            </div>
            <img src={accountStore.cards[0].image} alt="Post" style={{ width: '100%', maxHeight: 150, objectFit: 'cover', borderRadius: 8 }} />
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div style={{ display: 'flex', padding: '12px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="./heart.svg" alt="Like" style={{ height: 24, marginRight: 8 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="./comment.svg" alt="Comment" style={{ height: 24, marginRight: 8 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="./shareInstagram.svg" alt="Share" style={{ height: 24, marginRight: 8 }} />
                </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="./bookmark.svg" alt="Share" style={{ height: 24, marginRight: 8 }} />
                </div>
            </div>
            <div style={{ padding: '12px', fontFamily: 'Arial, sans-serif' }}>
                <p style={{ margin: 0, fontSize: 14 }}>
                    <strong>{accountStore.accountName} </strong>
                    <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} />
                </p>
                <div style={{ color: '#00376b', fontSize: 12, marginTop: 8 }}>
                    {accountStore.cards[0].hashtags}
                </div>
            </div>
            
        </div>
    );
});
export default InstagramPreview;