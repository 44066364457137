import { observer } from 'mobx-react';
import React, { useState, useContext, useEffect } from 'react';
import { AccountStoreContext } from '../store/AccountStore';
import { Timestamp } from 'firebase/firestore';

const TimeDropdown = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  const [time, setTime] = useState('12:00');
  const [hour, setHour] = useState('12');
  const [minute, setMinute] = useState('00');

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i % 15 === 0 ? i.toString().padStart(2, '0') : null).filter(Boolean);

  const handleHourChange = (event) => {
    const newHour = event.target.value;
    setHour(newHour);
    updateDate(newHour, minute);
  };

  const handleMinuteChange = (event) => {
    const newMinute = event.target.value;
    setMinute(newMinute);
    updateDate(hour, newMinute);
  };

  const updateDate = (newHour, newMinute) => {
    const date = accountStore.startDate.toDate();
    console.log("AccountStoreDate", date);
    const updatedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      parseInt(newHour, 10),
      parseInt(newMinute, 10)
    );
    setTime(`${newHour}:${newMinute}`);
    const updatedTimestamp = Timestamp.fromDate(updatedDate);
    accountStore.setStartDate(updatedTimestamp);
    console.log('Updated Start Date:', updatedDate);
  };

  useEffect(() => {
    console.log('Time', time);
  });

  return (
    <div style={{ display: 'flex', justifyContent:'center', padding: '20px 0px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px -1px 0px inset' }}>
      <select value={time.split(':')[0]} onChange={handleHourChange}>
            {hours.map((hour) => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </select>
      :
      <select value={time.split(':')[1]} onChange={handleMinuteChange}>
            {minutes.map((minute) => (
              <option key={minute} value={minute}>
                {minute}
              </option>
            ))}
          </select>
    </div>
  );
})
export default TimeDropdown;