import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';
import { observer } from 'mobx-react-lite';

const FormMenu = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  return (
    <>
    {!accountStore.showProfile ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <img src="./link.svg" alt="" />
        <input id="backlink-url" style={{ background: 'rgba(0,0,0,0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.1)', position: 'relative', textDecoration: 'none', listStyle: 'none', width: '100%', display: 'flex', flexDirection: 'column', height: 40, outline: 'none', border: 0, paddingLeft: 8 }}
          onChange={(e) => {
            accountStore.setBackLink(e.target.value);
          }} 
          placeholder='Backlink URL'
          value={accountStore.backlink} />
      </div>
      <div style={{ display: 'flex' }}>
        <img src="./hashtags.svg" alt="" />
        <input id="hashtags" style={{ background: 'rgba(0,0,0,0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.1)', position: 'relative', textDecoration: 'none', listStyle: 'none', width: '100%', display: 'flex', flexDirection: 'column', height: 40, outline: 'none', border: 0, paddingLeft: 8 }}
          placeholder='Hashtags'
          value={accountStore.hashtags}
          onChange={(e) => {
            accountStore.setHashTags(e.target.value);
          }} />
      </div>
      <div style={{ display: 'flex' }}>
        <img src="./megaphone.svg" alt="" />
        <input id="tone" style={{ background: 'rgba(0,0,0,0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.1)', position: 'relative', textDecoration: 'none', listStyle: 'none', width: '100%', display: 'flex', flexDirection: 'column', height: 40, outline: 'none', border: 0, paddingLeft: 8 }}
          placeholder='Tone'
          value={accountStore.tone}
          onChange={(e) => {
            accountStore.setTone(e.target.value);
          }} />
      </div>
      <div style={{ display: 'flex' }}>
        <img src="./dots.svg" alt="" />
        <input id="wordCount" style={{ background: 'rgba(0,0,0,0.0001)', boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.1)', position: 'relative', textDecoration: 'none', listStyle: 'none', width: '100%', display: 'flex', flexDirection: 'column', height: 40, outline: 'none', border: 0, paddingLeft: 8 }}
          placeholder='WordCount'
          value={accountStore.wordCount}
          onChange={(e) => {
            accountStore.setWordCount(e.target.value);
          }} />
      </div>
    </div>
    ) : (
      ""
    )}
    </>
  );
})
export default FormMenu;