import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore.js';

const SocialMediaConnection = observer(() => {
  const accountStore = useContext(AccountStoreContext);
  return(
    <div style={{ width: '75%', background: 'white', position: 'relative', marginTop: 20, borderRadius: 15, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 32px', marginBottom: 20, maxHeight: 400, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 16, paddingLeft: 0, paddingRight: 0 }}>
      <div style={{ width: 'calc(100% - 70px)', display: 'flex', justifyContent: 'space-between', marginBottom: 5, marginTop: 5, cursor: 'pointer' }}
        onClick={() => {
          if (accountStore.facebook === false) {
            accountStore.setFacebook(true);
          } else {
            accountStore.setFacebook(false);

          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundImage: `url(./facebook.svg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: 30, width: 30 }}/>
          <p style={{ fontFamily: 'nexa_boldregular', fontWeight: 'bold', marginLeft: 20 }}>
            Facebook
          </p>
        </div>
        <div style={{ height: 50, width: 100, borderRadius: 15, fontFamily: 'nexa_boldregular', alignItems: 'center', border: accountStore.facebook === true ? '1px solid black' : '0px solid black' }} className={accountStore.facebook === false ? "button" : ""}>
          <p style={{ color: accountStore.facebook === false ? 'white' : 'black', fontFamily: 'nexa_boldregular', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {accountStore.facebook === false ? 'Connect' : 'Disconnect'}
          </p>
        </div>
      </div>
      <div style={{ width: 'calc(100% - 70px)', display: 'flex', justifyContent: 'space-between', marginBottom: 5, marginTop: 5, cursor: 'pointer' }}
        onClick={() => {
          if (accountStore.instagram === false) {
            accountStore.setInstagram(true);
          } else {
            accountStore.setInstagram(false);

          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundImage: `url(./ig.svg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: 30, width: 30 }}/>
          <p style={{ fontFamily: 'nexa_boldregular', fontWeight: 'bold', marginLeft: 20 }}>
            Instagram
          </p>
        </div>
        <div style={{ height: 50, width: 100, borderRadius: 15, fontFamily: 'nexa_boldregular', alignItems: 'center', border: accountStore.instagram === true ? '1px solid black' : '0px solid black' }} className={accountStore.instagram === false ? "button" : ""}>
          <p style={{ color: accountStore.instagram === false ? 'white' : 'black', fontFamily: 'nexa_boldregular', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {accountStore.instagram === false ? 'Connect' : 'Disconnect'}
          </p>
        </div>
      </div>
      <div style={{ width: 'calc(100% - 70px)', display: 'flex', justifyContent: 'space-between', marginBottom: 5, marginTop: 5, cursor: 'pointer' }}
        onClick={() => {
          if (accountStore.twitter === false) {
            accountStore.setTwitter(true);
          } else {
            accountStore.setTwitter(false);

          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundImage: `url(./twitter.svg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: 30, width: 30 }}/>
          <p style={{ fontFamily: 'nexa_boldregular', fontWeight: 'bold', marginLeft: 20 }}>
            Twitter
          </p>
        </div>
        <div style={{ height: 50, width: 100, borderRadius: 15, fontFamily: 'nexa_boldregular', alignItems: 'center', border: accountStore.twitter === true ? '1px solid black' : '0px solid black' }} className={accountStore.twitter === false ? "button" : ""}>
          <p style={{ color: accountStore.twitter === false ? 'white' : 'black', fontFamily: 'nexa_boldregular', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {accountStore.twitter === false ? 'Connect' : 'Disconnect'}
          </p>
        </div>
      </div>
      <div style={{ width: 'calc(100% - 70px)', display: 'flex', justifyContent: 'space-between', marginBottom: 5, marginTop: 5, cursor: 'pointer' }}
        onClick={() => {
          if (accountStore.linkedIn === false) {
            accountStore.setLinkedIn(true);
          } else {
            accountStore.setLinkedIn(false);

          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundImage: `url(./linkedin.svg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: 30, width: 30 }}/>
          <p style={{ fontFamily: 'nexa_boldregular', fontWeight: 'bold', marginLeft: 20 }}>
            LinkedIn
          </p>
        </div>
        <div style={{ height: 50, width: 100, borderRadius: 15, fontFamily: 'nexa_boldregular', alignItems: 'center', border: accountStore.linkedIn === true ? '1px solid black' : '0px solid black' }} className={accountStore.linkedIn === false ? "button" : ""}>
          <p style={{ color: accountStore.linkedIn === false ? 'white' : 'black', fontFamily: 'nexa_boldregular', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {accountStore.linkedIn === false ? 'Connect' : 'Disconnect'}
          </p>
        </div>
      </div>
      <div style={{ width: 'calc(100% - 70px)', display: 'flex', justifyContent: 'space-between', marginBottom: 5, marginTop: 5, cursor: 'pointer' }}
        onClick={() => {
          if (accountStore.blog === false) {
            accountStore.setBlog(true);
          } else {
            accountStore.setBlog(false);

          }
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ backgroundImage: `url(./blog.svg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height: 30, width: 30 }}/>
          <p style={{ fontFamily: 'nexa_boldregular', fontWeight: 'bold', marginLeft: 20 }}>
            Blog
          </p>
        </div>
        <div style={{ height: 50, width: 100, borderRadius: 15, fontFamily: 'nexa_boldregular', alignItems: 'center', border: accountStore.blog === true ? '1px solid black' : '0px solid black' }} className={accountStore.blog === false ? "button" : ""}>
          <p style={{ color: accountStore.blog === false ? 'white' : 'black', fontFamily: 'nexa_boldregular', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {accountStore.blog === false ? 'Connect' : 'Disconnect'}
          </p>
        </div>
      </div>
    </div>
  );
})
export default SocialMediaConnection;