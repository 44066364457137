import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AccountStoreContext } from '../store/AccountStore';

const LinkedInPreviewMobile = observer(() => {
    const accountStore = useContext(AccountStoreContext);

    const formatText = (text) => {
        const regex = /<b>(.*?)<\/b>/g;
        const newText = text.replace(regex, '<strong>$1</strong>');
        return { __html: newText } ;
    };

    return(
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#FFFFFF', borderRadius: 8, overflow: 'hidden', justifyContent: 'space-between', height: '100%' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #ddd', marginBottom: 12 }}>
            <img src={accountStore.profileImage} alt="Profile" style={{ height: 40, width: 40, borderRadius: '50%', marginRight: 8 }} />
            <div>
              <span style={{ fontFamily: 'Arial, sans-serif', fontSize: 14, fontWeight: 'bold' }}>
                {accountStore.accountName}
              </span>
              <div style={{ fontSize: 10, color: '#666' }}>
                1 minute ago
              </div>
            </div>
          </div>
          <div style={{  fontFamily: 'Arial, sans-serif' }}>
            <p style={{ margin: 0, fontSize: 14, color: '#333', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
              {accountStore.cards[0].title}
              <span dangerouslySetInnerHTML={formatText(accountStore.cards[0].text)} />
            </p>
            <div style={{ color: '#0073b1', fontSize: 12, marginTop: 6 }}>
              {accountStore.cards[0].hashtags}
            </div>
          </div>
          {accountStore.cards[0].image && (
            <img src={accountStore.cards[0].image} alt="Post" style={{ width: '100%', height: 'auto', objectFit: 'cover', marginBottom: 8 }} />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ddd', fontSize: 12, paddingBottom: 8, paddingTop: 8 }}>
          <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
            <img src="./likeLinkedIn.svg" alt="Like" style={{ height: 20, marginRight: 6 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
            <img src="./commentLinkedIn.svg" alt="Comment" style={{ height: 20, marginRight: 6 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
            <img src="./shareLinkedIn.svg" alt="Share" style={{ height: 20, marginRight: 6 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', color: '#0073b1' }}>
            <img src="./sendLinkedIn.svg" alt="Send" style={{ height: 20, marginRight: 6 }} />
          </div>
        </div>
      </div>
    );
});
export default LinkedInPreviewMobile;